import React, {Suspense, useEffect} from 'react';
import './App.css';
import {Route, Switch} from 'react-router-dom';
import {useRecoilState} from "recoil";
import {ipAddressState, userIdState} from "./services/recoil";
import publicIp from "public-ip";
import {useMediaQuery} from "react-responsive";
import CustomBackdrop from "./components/molecules/CustomBackdrop";
import ReactPixel, {AdvancedMatching} from "react-facebook-pixel";
import * as Sentry from "@sentry/react";
import {retryLazy} from "./utils/Lazy";


const Store = retryLazy(() => import('./components/pages/Store'));
const ProductDetail = retryLazy(() => import('./components/pages/ProductDetail'));
const Cart = retryLazy(() => import("./components/pages/Cart"));
const Home = retryLazy(() => import("./components/pages/Home"));
const Order = retryLazy(() => import("./components/pages/Order"));
const Auth = retryLazy(() => import("./components/pages/Auth"));
const AuthNaver = retryLazy(() => import("./components/pages/Auth/Naver"));
const AuthSelect = retryLazy(() => import("./components/pages/Auth/Select"));
const SignUp = retryLazy(() => import('./components/pages/SignUp'));
const Agreements = retryLazy(() => import("./components/pages/Agreements"));
const ServiceTerms = retryLazy(() => import("./components/pages/Terms/ServiceTerms"));
const PrivacyTerms = retryLazy(() => import("./components/pages/Terms/PrivacyTerms"));
const FindId = retryLazy(() => import("./components/pages/FindId"));
const UpdatePassword = retryLazy(() => import("./components/pages/UpdatePassword"));
const UpdateAccount = retryLazy(() => import("./components/pages/UpdateAccount"));
const Refund = retryLazy(() => import("./components/pages/Refund"));
const OrderHistory = retryLazy(() => import("./components/pages/OrderHistory"));
const OrderConfirmation = retryLazy(() => import("./components/pages/OrderConfirmation"));
const ScreenSizeNotification = retryLazy(() => import("./components/atoms/ScreenSizeNotification"));
const MarketingTerms = retryLazy(() => import("./components/pages/Terms/MarketingTerms"));
const ThirdPartyTermsRequire = retryLazy(() => import("./components/pages/Terms/ThirdPartyTerms"));
const RefundInfo = retryLazy(() => import("./components/pages/RefundInfo"));
const RefundNotice = retryLazy(() => import("./components/pages/RefundNotice"));

interface AdvancedMatchingOptions extends AdvancedMatching {
    external_id: string;
}

const App: React.FC = () => {

        const [ipAddress, setIpAddress] = useRecoilState(ipAddressState);
        const [userId, setUserId] = useRecoilState(userIdState);

        useEffect(() => {
            publicIp.v4().then((ip) => {
                setIpAddress(ip);
                setUserId(userId);
                const advancedMatchingOptions: AdvancedMatchingOptions = {
                    ct: '', country: '', db: '', em: '', fn: '', ge: '', ln: '', ph: '', st: '', zp: '', external_id: userId
                };
                ReactPixel.init('411610583478904', advancedMatchingOptions, {autoConfig: true, debug: false});
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        // TODO: add strategy to handle different screen sizes
        // const isDesktop = useMediaQuery({query: '(min-device-width: 1224px)'});
        // const isBigScreen = useMediaQuery({query: '(min-device-width: 1824px)'});
        const isTabletOrMobile = useMediaQuery({query: '(max-width: 1224px)'});
        // const isTabletOrMobileDevice = useMediaQuery({query: '(max-device-width: 1224px)'});
        // const isPortrait = useMediaQuery({query: '(orientation: portrait)'});
        // const isRetina = useMediaQuery({query: '(min-resolution: 2dppx)'});

        function setDocHeight() {
            const basePercent = Math.max(window.innerHeight, window.innerWidth) / 100;
            document.documentElement.style.setProperty('--vw', `${window.innerWidth / 100}px`);
            document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
            document.documentElement.style.setProperty('--vmax', `${basePercent}px`);
            document.documentElement.style.setProperty('--em', `${basePercent + 4}px`);
        }

        window.addEventListener('resize', setDocHeight);
        window.addEventListener('orientationchange', setDocHeight);

        setDocHeight();

        return (<>
            <meta name={'viewport'} content={'width=device-width, user-scalable=no'}/>
            <Suspense fallback={<CustomBackdrop show={true}/>}>
                {!!ipAddress ? isTabletOrMobile ?
                    <Switch>
                        <Route exact path={'/'} children={<Auth/>}/>
                        <Route exact path={'/auth'} children={<Auth/>}/>
                        <Route exact path={'/auth/naver'} children={<AuthNaver/>}/>
                        <Route exact path={'/auth/select'} children={<AuthSelect/>}/>
                        <Route exact path={'/sign-up'} children={<SignUp/>}/>
                        <Route exact path={'/agreements'} children={<Agreements/>}/>
                        <Route exact path={'/service-terms'} children={<ServiceTerms/>}/>
                        <Route exact path={'/privacy-terms'} children={<PrivacyTerms/>}/>
                        <Route exact path={'/marketing-terms'} children={<MarketingTerms/>}/>
                        <Route exact path={'/third-party-terms'} children={<ThirdPartyTermsRequire/>}/>
                        <Route exact path={'/home'} children={<Home/>}/>
                        <Route exact path={'/store/:storeId/index'} children={<Store/>}/>
                        <Route exact path={'/store/:storeId/:productId/index'} children={<ProductDetail/>}/>
                        <Route exact path={'/cart'} children={<Cart/>}/>
                        <Route exact path={'/order'} children={<Order/>}/>
                        <Route exact path={'/find-id'} children={<FindId/>}/>
                        <Route exact path={'/update-password'} children={<UpdatePassword/>}/>
                        <Route exact path={'/update-account'} children={<UpdateAccount/>}/>
                        <Route exact path={'/refund/:orderId/:productId/index'} children={<Refund/>}/>
                        <Route exact path={'/order-history/:orderId/index'} children={<OrderHistory/>}/>
                        <Route exact path={'/order-confirmation'} children={<OrderConfirmation/>}/>
                        <Route exact path={'/refund-info'} children={<RefundInfo/>}/>
                        <Route exact path={'/refund-notice/:isRefund'} children={<RefundNotice/>}/>
                    </Switch> :
                    <ScreenSizeNotification/> :
                    <CustomBackdrop show={!ipAddress}/>
                }
            </Suspense>
        </>);
    }
;

export default Sentry.withProfiler(App);
