import {v4} from "uuid";
import {atom} from "recoil";
import {recoilPersist} from "recoil-persist";
import {AuthCreate} from "../../models/Account";
import {ProductInfo} from "../../models/Product";
import {CartProductEntity, StoreCartProduct} from "../../models/Cart";

const {persistAtom} = recoilPersist();

export const ipAddressState = atom({key: 'IpAddress', default: '', effects_UNSTABLE: [persistAtom]});

export const userIdState = atom({key: 'UserId', default: v4(), effects_UNSTABLE: [persistAtom]});

export const authProviderState = atom({key: 'AuthProvider', default: '', effects_UNSTABLE: [persistAtom]});

export const accessTokenState = atom({key: 'AccessToken', default: '', effects_UNSTABLE: [persistAtom]});

export const authCreateState = atom({key: 'AuthCreate', default: {} as AuthCreate, effects_UNSTABLE: [persistAtom]});

export const timerState = atom({key: 'Timer', default: 0});

export const unAuthCartState = atom({key: 'UnAuthCart', default: [] as Array<ProductInfo>, effects_UNSTABLE: [persistAtom]});

export const unAuthOrderState = atom({key: 'UnAuthOrder', default: {} as StoreCartProduct, effects_UNSTABLE: [persistAtom]});

export const cartProductState = atom({key: 'CartProduct', default: [] as Array<CartProductEntity>});

export const resetCartProductEntityIdState = atom({key: 'CartProductEntityId', default: [] as Array<string>});

export const storeCallbackState = atom({key: 'StoreCallback', default: new Map() as Map<string, string | undefined>});

export const orderProductState = atom({key: 'OrderProduct', default: [] as Array<StoreCartProduct>, effects_UNSTABLE: [persistAtom]});
