import {lazy} from "react";


export const retryLazy = (componentImport: any) =>
    lazy(async () => {
        const pageAlreadyRefreshed = JSON.parse(window.localStorage.getItem('pageRefreshed') || 'false');
        try {
            const c = await componentImport();
            window.localStorage.setItem('pageRefreshed', 'false');
            return c;
        } catch (err) {
            if (!pageAlreadyRefreshed) {
                window.localStorage.setItem('pageRefreshed', 'true');
                return window.location.reload();
            }
            throw err;
        }
    });
